export const firebaseConfig = {
  apiKey: "AIzaSyAMRs2QflB7xJ64Hc6ODkGuWIvvDwgNYXM",
  authDomain: "mci-screener.firebaseapp.com",
  projectId: "mci-screener",
  storageBucket: "mci-screener.appspot.com",
  messagingSenderId: "420723284786",
  appId: "1:420723284786:web:090904d7f9f0052e45152b",
  measurementId: "G-3D74YNYHZR",
};

export const appCheck = "6Lci-cAeAAAAAFE_XOKDDtzolH5dtI0owK0IT8P7";
